<template>
  <div>
    <v-app-bar
      id="app-bar"
      app
      dense
      flat
      :class="$vuetify.theme.dark ? 'dark-top-bar' : 'white'"
    >
      <v-app-bar-nav-icon
        v-if="showBackButton"
        @click="gotBack()"
      >
        <template v-slot:default>
          <v-icon>
            mdi-apps
            <!-- {{ $vuetify.rtl ? 'mdi-chevron-right' : 'mdi-chevron-left' }} -->
          </v-icon>
        </template>
      </v-app-bar-nav-icon>

      <!-- <v-toolbar-items class="mx-2">
        <v-icon>{{ sectionTitle.icon }}</v-icon>
      </v-toolbar-items> -->

      <v-toolbar-title>
        {{ $t(sectionTitle.title) }}
      </v-toolbar-title>

      <v-spacer />
      <!-- change theme -->
      <v-btn
        icon
        small
        class="me-3"
        @click="changeTheme()"
      >
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <!-- Notification center -->
      <v-menu
        ref="companiesNotificationMenu"
        bottom
        offset-y
        :close-on-click="true"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            :content="companiesNotification.count ? companiesNotification.count : 0"
            :value="companiesNotification.count ? companiesNotification.count : 0"
            color="error"
            class="me-6"
            overlap
            offset-x="0"
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
            >
              mdi-bell
            </v-icon>
          </v-badge>
        </template>

        <v-card
          width="400"
          max-height="650"
          class="notification-card"
        >
          <v-list
            subheader
            dense
            nav
            class="pt-2"
          >
            <v-subheader class="text-subtitle-1 font-weight-bold ps-4">
              {{ $t('Lawyer Contract') }}

              <v-chip
                small
                class="ms-2 "
              >
                {{ companiesNotification.lawyer_contract.length }}
              </v-chip>
            </v-subheader>

            <v-list-item
              v-for="(lawyerContract, index) in companiesNotification.lawyer_contract"
              :key="`${index}-${lawyerContract.id}`"
            >
              <div class="d-flex">
                <v-list-item-avatar size="38">
                  <img :src="`${$_file_path()}/media/${lawyerContract.company_logo}?token=${$_auth_token()}`">
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text-body-2 font-weight-bold pb-1">
                    {{ lawyerContract.company_name }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <div
                      class="grey--text"
                      :class="{
                        'text--darken-2': !$vuetify.theme.dark,
                        'text--lighten-2': $vuetify.theme.dark,
                      }"
                    >
                      <div>
                        {{ lawyerContract.last_end_date }}
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
            </v-list-item>

            <v-divider class="my-3" />

            <v-subheader class="text-subtitle-1 font-weight-bold ps-4">
              {{ $t('Social Security') }}

              <v-chip
                small
                class="ms-2 "
              >
                {{ companiesNotification.social_security.length }}
              </v-chip>
            </v-subheader>

            <v-list-item
              v-for="(socialSecurity, index) in companiesNotification.social_security"
              :key="`${index}-${socialSecurity.id}`"
            >
              <div class="d-flex">
                <v-list-item-avatar size="38">
                  <img :src="`${$_file_path()}/media/${socialSecurity.company_logo}?token=${$_auth_token()}`">
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text-body-2 font-weight-bold pb-1">
                    {{ socialSecurity.full_name }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <div
                      class="grey--text"
                      :class="{
                        'text--darken-2': !$vuetify.theme.dark,
                        'text--lighten-2': $vuetify.theme.dark,
                      }"
                    >
                      <div>
                        {{ socialSecurity.company_name }}
                        <span class="ms-4">
                          {{ $_number_format(socialSecurity.salary) }}
                        </span>
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
            </v-list-item>

            <v-divider class="my-3" />

            <v-subheader class="text-subtitle-1 font-weight-bold ps-4">
              {{ $t('Seasonal & Yearly Form') }}

              <v-chip
                small
                class="ms-2 "
              >
                {{ companiesNotification.seasonal_form.length }}
              </v-chip>
            </v-subheader>

            <v-list-item
              v-for="(seasonalForm, index) in companiesNotification.seasonal_form"
              :key="`${index}-${seasonalForm.id}`"
            >
              <div class="d-flex">
                <v-list-item-avatar size="38">
                  <img :src="`${$_file_path()}/media/${seasonalForm.company_logo}?token=${$_auth_token()}`">
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title class="text-body-2 font-weight-bold pb-1">
                    {{ seasonalForm.company_name }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-wrap">
                    <div
                      class="grey--text"
                      :class="{
                        'text--darken-2': !$vuetify.theme.dark,
                        'text--lighten-2': $vuetify.theme.dark,
                      }"
                    >
                      <div>
                        {{ seasonalForm.end_date }}
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        left
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >
            {{ authUserData.username }}
            <v-icon>mdil-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="goToProfile()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                {{ $t('Profile') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="setLtr()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-translate</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                English
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="setRtl()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-translate</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                کوردی
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
          <v-list-item @click="changeTheme()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title>
                {{ $t('Dark mode') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
          <v-list-item @click="logoutUser()">
            <v-list-item-icon class="ms-1 me-3">
              <v-icon color="error">
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="me-3">
              <v-list-item-title class="error--text">
                {{ $t('Logout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      showBackButton: false
    }
  },

  computed: {
    ...mapGetters([
      'sectionTitle',
      'authUserData',
      'authShareholder',
      'companiesNotification',
    ])
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.name == 'dashboard') {
          this.$_section_title({ title: 'Home' })
          this.showBackButton = false
        } else {
          this.showBackButton = true
        }
      }
    }
  },

  mounted() {
    this.$_section_title({ title: 'Home' })
    if (this.authUserData) {
      this.checkCompaniesNotification()
    }

    window.addEventListener("scroll", () => {
      const appBar = document.getElementById("app-bar")
      if (
        document.body.scrollTop > 120 ||
        document.documentElement.scrollTop > 120
      ) {
        appBar.classList.add("bar-box-shadow")
      } else {
        appBar.classList.remove("bar-box-shadow")
      }
    });
  },

  methods: {
    ...mapActions([
      'toggleRtl',
      'checkCompaniesNotification',
      'toggleTheme',
      'setLocale',
      'logout'
    ]),

    goToProfile() {
      this.$router.push({ name: 'admin-profile' })
    },

    gotBack() {
      this.$router.push({ name: 'admin-panel.dashboard' })
    },

    logoutUser() {
      this.logout().then(() => {
        this.$router.push('/')
      })
    },

    setRtl() {
      this.$vuetify.rtl = true
      this.$i18n.locale = 'ckb'
      this.toggleRtl(true)
      this.setLocale('ckb')
    },

    setLtr() {
      this.$vuetify.rtl = false
      this.$i18n.locale = 'en'
      this.toggleRtl(false)
      this.setLocale('en')
    },

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.toggleTheme(this.$vuetify.theme.dark)
    }
  },
}
</script>

<style lang="scss" scoped>
.dark-top-bar {
  background-color: #1e1e1e !important;
}
</style>